<template>
  <Dialog
    v-if="true"
    :name="name"
    width="728px"
    class-own="dialog-delete-timeslot"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">Подтверждение приезда</div>
    </template>

    <div>
      <div class="dialog-delete-timeslot__message">
        Вы действительно хотите подтвердить приезд авто
        <b>{{ truckNumber }}</b> {{ cultureName }}?
      </div>

      <div class="df jc-sb">
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-right: 4px"
          text-style="color: #000000; font-weight: 500"
          title="Отмена"
          :disabled="isLoading"
          @onClick="handleForceArrival(false)"
        />
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white"
          text-style="color: white; font-weight: 500"
          title="Подтвердить"
          :loading="isLoading"
          @onClick="handleForceArrival(true)"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import {
  FORCE_ARRIVAL_TIMESLOT,
  GET_IS_LOADING_POST_TIMESLOTS,
} from '@/views/manager/store/actions'
import { MANAGER_FORCE_ARRIVAL } from '@/constants/dialogs'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqMobileButton from '@/UI/form/mobileButton/IqMobileButton'

export default {
  name: 'DialogForceArrival',
  components: { IqMobileButton, Dialog },
  data() {
    return { name: MANAGER_FORCE_ARRIVAL }
  },
  computed: {
    ...mapGetters({ isLoading: GET_IS_LOADING_POST_TIMESLOTS }),
    dialogData() {
      return this.getDialog(this.name)?.data
    },
    truckNumber() {
      return this.dialogData?.plate_truck || ''
    },
    cultureName() {
      return this.dialogData?.culture_name || ''
    },
  },
  methods: {
    ...mapActions({ forceArrival: FORCE_ARRIVAL_TIMESLOT }),
    handleForceArrival(isRemove = false) {
      if (isRemove) {
        const requestData = {
          timeslot_id: this.dialogData.id,
          plate_truck: this.dialogData.plate_truck,
        }

        this.forceArrival(requestData)
      }
      this.setDialog({ name: this.name })
    },
  },
}
</script>

<style lang="sass">
.manager-delete-timeslot
  .el-dialog__header
    background: #F56C6C!important

.dialog-delete-timeslot
  &__message
    color: #303133
    margin-top: 16px
    margin-bottom: 24px
    font-size: 16px
  .el-button + .el-button
    margin-left: 35px
</style>
